import React, { useEffect, useState, useMemo, useRef } from "react";

const url = //"";
// "https://api.pcloud.com/getpubvideolinks?code=XZxgI50ZTyXrRCSEskhbBXSLAKaAOHIrvPGy";
 //"https://u.pcloud.link/publink/show?code=XZxgI50ZTyXrRCSEskhbBXSLAKaAOHIrvPGy";
 "https://u.pcloud.link/publink/show?code=XZhsa50ZqVro1RRpbzFHi9XQikInCSoqpiyX";

 function Iframe(url) {
    return (
      <iframe
        id="myFrame"
        className="page-class"
        title="page"
        src={url}
        width="100%"
        height="600px"
        allow="autoplay"
        //loading="eager"
        scrolling="no"
      />
    );
  }
  const ViewPage = () => {
  const [videoElement, setVideoElement] = useState(null);
  //const getIframe = useMemo(() => Iframe(url));

  useEffect(() => {
    setVideoElement();
  }, [videoElement]);
  //console.log(videoElement?.contentWindow)
  return <>{};</>;
};

export default ViewPage;
