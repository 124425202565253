import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import {
  Route,
  Routes,
  useLocation,
  // useNavigate,
  // Link,
} from "react-router-dom";
import {
  useQuery,
  // useMutation,
  // useQueryClient,
  QueryClient,
  QueryClientProvider,
} from "react-query";
// import { classNames } from "primereact/utils";

import { AuthContext, authValues } from "./context/AuthContext";

import { NotFound } from "./Pages/NotFound";
import { Access } from "./Pages/Access";
// // import { Button } from "primereact/button";
// import GuardedRoute from "./components/router/guardedRoute/GuardedRoute";
import ProtectedRoute from "./components/router/protectedRoute/ProtectedRoute";
import AppFooter from "./components/menus/AppFooter";
import AppMenu from "./components/menus/AppMenu";
import Home from "./Pages/Home/Home";
import Ski from "./components/bookSki/ski/Ski";

// import nodes from "./common/nodes.json";
import firebaseService from "./services/firebase";
import userSessionStorage from "./services/userSessionStorage";
// import { nodeModel } from "./common/models";

import Administration from "./components/administation/Administation";
import Login from "./components/auth/login/Login";
import Logout from "./components/auth/logout/Logout";

import "./App.scss";
import ViewPage from "./components/viewPage/ViewPage";
// import CustomIframe from "./components/viewPage/CustomIframe";

const queryClient = new QueryClient();

const App = (props) => {
  // const [colorScheme, setColorScheme] = useState("light");
  // const [theme, setTheme] = useState("blue");
  // const [componentTheme, setComponentTheme] = useState("blue");
  const location = useLocation();

  // const [rightMenuActive, setRightMenuActive] = useState(false);
  // const [configActive, setConfigActive] = useState(false);
  const [menuMode, setMenuMode] = useState("sidebar");
  const [overlayMenuActive, setOverlayMenuActive] = useState(false);
  // const [ripple, setRipple] = useState(true);
  const [sidebarStatic, setSidebarStatic] = useState(false);
  // const [staticMenuDesktopInactive, setStaticMenuDesktopInactive] =
  //   useState(false);
  const [staticMenuMobileActive, setStaticMenuMobileActive] = useState(false);
  const [menuActive, setMenuActive] = useState(false);
  // const [searchActive, setSearchActive] = useState(false);
  // const [topbarMenuActive, setTopbarMenuActive] = useState(false);
  const [sidebarActive, setSidebarActive] = useState(false);
  const [pinActive, setPinActive] = useState(false);
  const [activeInlineProfile, setActiveInlineProfile] = useState(false);
  const [resetActiveIndex, setResetActiveIndex] = useState(null);
  const [changeLanguage, setChangeLanguage] = useState(false);

  const [user, setUser] = useState({});
  const [nodes, setNodes] = useState(
    firebaseService.nodes.filter((n) =>
      userSessionStorage.getAccessBook()
        ? userSessionStorage
            .getAccessBook()
            .split("-")
            .some((b) =>
              String(n.id).includes(
                `${b}|${userSessionStorage.getUserLocalize()}`
              )
            )
        : true
    )
  );
  //const [menu, setMenu] = useState([]);
  const copyTooltipRef = useRef();

  let menuClick;
  let routes = [];
  // const navigate = useNavigate();

  useMemo(() => {
    const loginUser = authValues.getLoginUser();
    if (loginUser) {
      userSessionStorage.setPr(loginUser?.pr);
      setUser(loginUser);
    } else if (userSessionStorage.getToken()) {
      authValues
        .getLoginUserEmailFromToken()
        .then((res) => {
          userSessionStorage.setPr(res?.pr);
          setUser(res);
        })
        .catch(() => {});
    }
  }, [userSessionStorage.getToken()]);

  useEffect(() => {
    firebaseService
      .getNodesData()
      .then((res) => {
        setNodes(
          res.filter((n) =>
            userSessionStorage.getAccessBook()
              ? userSessionStorage
                  .getAccessBook()
                  .split("-")
                  .some((b) =>
                    userSessionStorage.getUserLocalize() != "ff"
                      ? String(n.id).includes(
                          `${b}|${userSessionStorage.getUserLocalize()}`
                        )
                      : String(n.id).includes(`${b}`)
                  )
              : true
          )
        );
        setChangeLanguage(false);
      })
      .catch(() => {});
  }, [changeLanguage]);

  useEffect(() => {
    if (
      location.pathname != "/administration" &&
      userSessionStorage.getLocalize() != "en" &&
      userSessionStorage.getLocalize() != "bg"
    ) {
      userSessionStorage.setDefaultLanguage();
      setChangeLanguage(true);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    copyTooltipRef &&
      copyTooltipRef.current &&
      copyTooltipRef.current.updateTargetEvents();
  }, [location]);

  useEffect(() => {
    setResetActiveIndex(true);
    setMenuActive(false);
  }, [menuMode]);

  //const menu = nodes;

  //  [
  //   {
  //     label: "Favorites",
  //     icon: "pi pi-home",
  //     items: [{ label: "Dashboard", icon: "pi pi-home", to: "/" }],
  //   },
  // ];

  // const layoutClassName = classNames("layout-wrapper", {
  //   "layout-static": menuMode === "static",
  //   "layout-overlay": menuMode === "overlay",
  //   "layout-overlay-active": overlayMenuActive,
  //   "layout-slim": menuMode === "slim",
  //   "layout-horizontal": menuMode === "horizontal",
  //   "layout-active": menuActive,
  //   "layout-mobile-active": staticMenuMobileActive,
  //   "layout-sidebar": menuMode === "sidebar",
  //   "layout-sidebar-static": menuMode === "sidebar" && sidebarStatic,
  //   "layout-static-inactive":
  //     staticMenuDesktopInactive && menuMode === "static",
  //   "p-ripple-disabled": !ripple,
  // });

  const isHorizontal = () => {
    return menuMode === "horizontal";
  };

  const isSlim = () => {
    return menuMode === "slim";
  };

  const isOverlay = () => {
    return menuMode === "overlay";
  };

  const isDesktop = () => {
    return window.innerWidth > 1000;
  };

  const hideOverlayMenu = () => {
    setOverlayMenuActive(false);
    setStaticMenuMobileActive(false);
  };

  const onChangeActiveInlineMenu = (event) => {
    setActiveInlineProfile((prevState) => !prevState);
    event.preventDefault();
  };

  const onToggleMenu = (event) => {
    menuClick = true;

    if (overlayMenuActive) {
      setOverlayMenuActive(false);
    }

    if (sidebarActive) {
      setSidebarStatic((prevState) => !prevState);
    }

    event.preventDefault();
  };

  const onSidebarMouseOver = () => {
    if (menuMode === "sidebar" && !sidebarStatic) {
      setSidebarActive(isDesktop());
      setTimeout(() => {
        setPinActive(isDesktop());
      }, 200);
    }
  };

  const onSidebarMouseLeave = () => {
    if (menuMode === "sidebar" && !sidebarStatic) {
      setTimeout(() => {
        setSidebarActive(false);
        setPinActive(false);
      }, 250);
    }
  };

  const onMenuClick = () => {
    menuClick = true;
  };

  const onMenuItemClick = (event) => {
    if (!event.item.items) {
      hideOverlayMenu();
      setResetActiveIndex(true);
    }

    if (!event.item.items && (isHorizontal() || isSlim())) {
      setMenuActive(false);
    }
  };

  const onRootMenuItemClick = () => {
    setMenuActive((prevState) => !prevState);
  };

  //recursion
  function extractUrls(menu, routes = [], localize) {
    for (const item of menu) {
      if (item?.url && item?.localize == localize) {
        routes.push(item.url);
      }
      if (item.children) {
        extractUrls(item.children, routes, localize);
      }
    }
    return routes;
  }

  const onLoginSubmitClick = (data) => {
    // setUser(firebaseService.getCurrentUser());
    setChangeLanguage(data?.changeLocalize);
  };

  const changeLanguageClick = (localize) => {
    setChangeLanguage(localize);
  };

  const appMenuComponent = () => {
    extractUrls(nodes, routes, userSessionStorage.getLocalize());
    //routes = menu.map((node) => node.url);

    // menu.forEach((first) => {
    //   if (first?.url) {
    //     routes.push(first?.url);
    //   }
    //   if (first.children) {
    //     first.children.forEach((second) => {
    //       if (second?.url) {
    //         routes.push(second?.url);
    //       }
    //       if (second.children) {
    //         second.children.forEach((third) => {
    //           if (third?.url) {
    //             routes.push(third?.url);
    //           }
    //           if (third.children) {
    //             third.children.forEach((fourth) => {
    //               if (fourth?.url) {
    //                 routes.push(fourth?.url);
    //               }
    //               if (fourth.children) {
    //                 fourth.children.forEach((fifth) => {
    //                   if (fifth?.url) {
    //                     routes.push(fifth?.url);
    //                   }
    //                   if (fifth.children) {
    //                     fifth.children.forEach((sixth) => {
    //                       if (sixth?.url) {
    //                         routes.push(sixth?.url);
    //                       }
    //                     });
    //                   }
    //                 });
    //               }
    //             });
    //           }
    //         });
    //       }
    //     });
    //   }
    // });
    // routes = [
    //   { route: "/first-steps-walking" },
    //   { route: "en/ski/level-a/first-steps/walking/bg/" },
    //   { route: "/:locale/ski/level-a/first-steps/walking" },
    //   { route: "/ski-first-steps" },
    //   { route: "methodologia/bg/bg/" },
    // ];

    return (
      <AppMenu
        model={nodes}
        onRootMenuItemClick={onRootMenuItemClick}
        onMenuItemClick={onMenuItemClick}
        onToggleMenu={onToggleMenu}
        onMenuClick={onMenuClick}
        menuMode={menuMode}
        colorScheme={props.colorScheme}
        menuActive={menuActive}
        sidebarActive={sidebarActive}
        sidebarStatic={sidebarStatic}
        pinActive={pinActive}
        onSidebarMouseLeave={onSidebarMouseLeave}
        onSidebarMouseOver={onSidebarMouseOver}
        activeInlineProfile={activeInlineProfile}
        onChangeActiveInlineMenu={onChangeActiveInlineMenu}
        resetActiveIndex={resetActiveIndex}
        changeLanguageClick={changeLanguageClick}
      />
    );
  };

  return (
    <>
      <AuthContext.Provider value={{ ...authValues, user, setUser }}>
        {appMenuComponent()}
        <QueryClientProvider client={queryClient}>
          <div className="layout-main-content">
            <Routes>
              <Route
                path="/"
                element={
                  <Home
                    changeLanguageClick={changeLanguageClick}
                    nodes={nodes}
                  />
                }
              />

              <Route
                path="/login/*"
                element={<Login onLoginSubmitClick={onLoginSubmitClick} />}
              />

              {/* <Route element={<GuardedRoute />}> */}
              <Route
                path="/logout"
                element={<Logout changeLanguageClick={changeLanguageClick} />}
              />
              {/* </Route> */}
              <Route
                path="/page"
                element={
                  // <CustomIframe>
                  <ViewPage />
                  // </CustomIframe>
                }
              />
              <Route
                path="/administration"
                element={
                  <ProtectedRoute role={"admin"}>
                    <Administration changeLanguageClick={changeLanguageClick} />
                  </ProtectedRoute>
                }
              />
              {routes.map((link) => (
                <Route
                  key={link}
                  path={link}
                  element={
                    <ProtectedRoute>
                      <Ski path={location?.pathname} />
                    </ProtectedRoute>
                  }
                />
              ))}
              <Route path="/access" element={<Access />} />
              <Route path="*" element={<NotFound />} />
            </Routes>

            <AppFooter colorScheme={"light"} />
          </div>
        </QueryClientProvider>
      </AuthContext.Provider>
    </>
  );
};
export default App;
