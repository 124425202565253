import { useContext, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import userSessionStorage from "../../../services/userSessionStorage";
import { AuthContext, authValues } from "../../../context/AuthContext";

function verifyAccess(location, role) {
  function resolveAccess() {
    if (
      String(authValues.userBookAccess()).includes(
        location.pathname?.split("/")[1]
      )
    ) {
      return true;
    } else if (role && location?.pathname?.includes(role)) {
      return true;
    }
    else if(location?.pathname && String(window.location.href.includes(`go=${location.pathname}`)) && !userSessionStorage.hasToken()){
          return true;
    }
    
    return null;
  }

  return resolveAccess();
}

//({ path, permission, children, ...others })
const ProtectedRoute = ({ children, role, uab }) => {
  const context = useContext(AuthContext);
  const isAuthenticated = context.isAuthenticated();
  const location = useLocation();
  const navigation = useNavigate();
  const hasUserBookAccess =
    location.pathname.length > 1 ? verifyAccess(location, role) : false;

  useEffect(() => {
    if (!isAuthenticated) {
      userSessionStorage.clearSession();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    setTimeout(() => {
      if (
        location.pathname.length > 1 ? !verifyAccess(location, role) : false
      ) {
        navigation("/access");
      }
    }, 1300);
  }, [verifyAccess(location, role)]);

  const grantedPermision = () => {
    return context.role() === role ? children : <Navigate to="/access" />;
  };

  const userHasPermision = () => {
    return hasUserBookAccess
      ? children
      : setTimeout(() => {
          if (verifyAccess(location)) {
            return children;
          } else {
            return <Navigate to="/access" />;
          }
        }, 1800);
  };

  return isAuthenticated ? (
    role ? (
      grantedPermision()
    ) : (
      // children
      userHasPermision()
    )
  ) : (
    <Navigate to={`/login/?go=${location.pathname}`} />
  );
};

export default ProtectedRoute;
