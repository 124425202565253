import React, { useState } from "react";

import firebaseService from "../services/firebase";
import userSessionStorage from "../services/userSessionStorage";
import { crypt, decrypt } from "../common/helpers";
import { Roles } from "../common/constants";
export const AuthContext = React.createContext();

function isAuthenticated() {
  return userSessionStorage.getToken();
}

let user = null;

function getLoginUser() {
  return user;
}

async function login(data) {
  return await firebaseService
    .getAuthUser(data)
    .then((userCredentialImpl) => {
      userSessionStorage.setToken(userCredentialImpl.user.accessToken);
    })
    .then(() => {
    return  getUserFromDb(data)
        .then((res) => {
          user = res;
          return res;
        })
        .catch(() => {});
    })
    .catch((err) => {
      //console.log(err);
      return "err";
    });
}

async function logout() {
  userSessionStorage.clearSession();
  firebaseService.signOutUser();
}

async function getUserFromDb(data) {
  let result = null;
  await firebaseService.getUser(data).then((res) => {
    let pr = "";
    res.forEach((doc) => {
      pr = `y${pr}y${String(doc.data()?.role)}y${doc.data()?.class}y${
        doc.data()?.uab
      }ea1cl${doc.data()?.localize}`;
      //sessionStorage.setItem("pr", pr);
      //user = doc.data()?.email;
      result = {...doc.data(), pr};
    });
  });
  return result;
}

function role() {
  if (userSessionStorage.getRole()) {
    let roleId = userSessionStorage.getRole();
    return Roles[roleId];
  }
  return null;
}

function userBookAccess() {
  return userSessionStorage.getAccessBook();
}

function isAdmin() {
  return role() === Roles[1];
}

function getLoginUserEmailFromToken(){
  const email = userSessionStorage.getEmailFromToken();
  
  return getUserFromDb({email})
  .then((res)=>{
    const pr = res?.pr;
    //sessionStorage.setItem("pr", pr);
    return res
  })
  .catch(()=>{})
}

export const authValues = {
  isAuthenticated,
  userBookAccess,
  getLoginUser,
  getLoginUserEmailFromToken,
  isAdmin,
  role,
  login,
  logout,
};
