import React, { useEffect, useState, useRef, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
// import ImageCanvas from "../imageCanvas/ImageCanvas";
import VideoPlayer from "../videoPlayer/VideoPlayer";
import ImageGaleria from "../imageGaleria/ImageGaleria";
// import IFrame from "../iframe/IFrame";
// import HtmlToJsonConverter from "../../services/HtmlToJsonConverter";
// import VideoComponent from "../videoComponent/VideoComponent";
//import VideoPlayer from "../videoCanvas/VideoCanvas";

import { cardModel, videoModel, iframeModel } from "../../common/models";
import firebaseService from "../../services/firebase";
import bookModifierService from "../../services/bookModifier";
// import db from "../../common/db.json";
import videos from "../../common/videos.json";
// import getVideoUrl from "../../services/pCloud";

import "./Card.css";
import userSessionStorage from "../../services/userSessionStorage";

const Card = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  // const [cardName, setCardName] = useState(null);
  const [card, setCard] = useState(cardModel);
  const [video, setVideo] = useState(videoModel);
  const [imagesItem, setImagesItem] = useState(null);
  // const [ifrmeValue, setIframeValue] = useState(iframeModel);
  // const [loadIframe, setLoadIframe] = useState(false);
  const [loadCard, setLoadCard] = useState(true);
  const [loadVideo, setLoadVideo] = useState(false);
  const [loadDescription, setLoadDescription] = useState(false);
  const [loadMethodology, setLoadMethodology] = useState(false);
  const [loadLearnErrors, setLoadLearnErrors] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1000);
  const [windowInnerWidth, setWindowInnerWidth] = useState(window.innerWidth);
  const [windowInnerHeight, setWindowInnerHeight] = useState(
    window.innerHeight
  );
  const [openTextImage, setOpenTextImage] = useState(false);
  const [textImage, setTextImage] = useState("");
  const [textVideo, setTextVideo] = useState(null);
  const [imageGaleriaNotLoaded, setImageGaleriaNotLoaded] = useState(false);
  const [showHomeBalloon, setShowHomeBalloon] = useState(false);
  const [showVideoBalloon, setShowVideoBalloon] = useState(false);
  const [showDescriptionBalloon, setShowDescriptionBalloon] = useState(false);
  const [showMethodologyBalloon, setShowMethodologyBalloon] = useState(false);
  const [showLearnErrorsBalloon, setShowLearnErrorsBalloon] = useState(false);

  const iframeRef = useRef(null);
  // const pCloudUrl =
  //   "https://api.pcloud.com/getpubvideolinks?code=XZYOP3VZ4WyYAUOuBgBb1IXbNBC3MhuSgAQy&linkpassword=123qwe";

  useEffect(() => {
    window.addEventListener("resize", function () {
      setIsMobile(window.innerWidth <= 1000);
      setWindowInnerWidth(window.innerWidth);
      setWindowInnerHeight(window.innerHeight);
    });
  }, [isMobile, windowInnerWidth]);

  useMemo(() => {
    //console.log(location.pathname?.substring(1));
    //setCardName(data?.value?.props);
    // setCard(db.find((x) => x.name === cardName));
    // setCard(dataFromDb);
    const document = bookModifierService.namePathConverter(location.pathname);
    const collectionGroup = document.includes("|ski|")
      ? "skies"
      : document.includes("|snowboard|")
      ? "snowboards"
      : "";
    firebaseService
      .getDataFromDbByDocId({
        collectionGroup: collectionGroup,
        document: document,
      })
      .then((res) => {
        if (!res) {
          navigate("/not-found");
        }
        //var img = bookModifierService.getImagePathByName(res?.img);
        setCard(res);
        setImagesItem(res?.img);
      }).catch(()=>{});

    if (props?.value?.path != location.pathname) {
      loadCardHandler();
    }
  }, [isMobile, props?.value?.path, imageGaleriaNotLoaded]);

  // useEffect(()=>{
  //   console.log(getVideoUrl({"url":pCloudUrl}))
  // },[]);

  useEffect(() => {
    setVideo({
      ...videos.find((x) => x.name === card?.videoName),
      height: isMobile ? "100%" : "80%",
      width: isMobile ? "100%" : "80%",
    });
    // setIframeValue({ url: pCloudUrl });
  }, [card]);

  const goHome = () => {
    if (!loadCard) {
      loadCardHandler();
    } else {
      navigate("/");
    }
  };

  const loadCardHandler = (data) => {
    setLoadCard(true);
    setLoadDescription(false);
    setLoadMethodology(false);
    setLoadLearnErrors(false);
    setLoadVideo(false);
  };

  const openVideo = (data) => {
    // console.log(data);
    setLoadCard(false);
    setLoadDescription(false);
    setLoadMethodology(false);
    setLoadLearnErrors(false);
    setLoadVideo(true);
  };

  const loadDescriptionHandler = (data) => {
    setLoadCard(false);
    setLoadDescription(true);
    setLoadMethodology(false);
    setLoadLearnErrors(false);
    setLoadVideo(false);
  };

  const loadMethodologyHandler = (data) => {
    setLoadCard(false);
    setLoadDescription(false);
    setLoadMethodology(true);
    setLoadLearnErrors(false);
    setLoadVideo(false);
  };

  const loadLearnErrorsHandler = (data) => {
    setLoadCard(false);
    setLoadDescription(false);
    setLoadMethodology(false);
    setLoadLearnErrors(true);
    setLoadVideo(false);
  };

  // const handleIframeLoad = () => {
  //   const iframeContentWindow = iframeRef?.current?.querySelector("iframe");
  //   const iframeDocument = iframeContentWindow;
  //   setLoadIframe(true);
  //   //console.log(iframeDocument);
  // };

  const spliterHandler = (textLine) => {
    if (textLine.includes("img{")) {
      return "img{";
    } else if ("vdo{") return "vdo{";
  };

  const swithButtonHandler = (line, pathOfImage, pathOfVideo) => {
    switch (spliterHandler(line)) {
      case "img{":
        return (
          <Button
            className="mr-1"
            icon="pi pi-image"
            // rounded
            raised
            size="small"
            outlined
            severity="info"
            aria-label="image"
            onClickCapture={() => {
              setOpenTextImage(true);
              setTextImage(pathOfImage);
            }}
          />
        );
      case "vdo{":
        return (
          <Button
            className="mr-1"
            icon="pi pi-video"
            // rounded
            raised
            size="small"
            outlined
            severity="danger"
            aria-label="image"
            onClickCapture={() => {
              setOpenTextImage(true);
              setTextVideo(pathOfVideo);
            }}
          />
        );
      default:
        return;
    }
  };

  const onImageGaleriaLoad = (e) => {
    if (imageGaleriaNotLoaded !== !e) {
      setImageGaleriaNotLoaded(!e);
    }
  };

  const onImageGaleriaFallBack = (e) => {
    setImagesItem(null);
    setTimeout(() => {
      if (imageGaleriaNotLoaded) {
        window.location.reload();
      }
    }, 1000);
    setImageGaleriaNotLoaded(e == undefined);
  };

  const cardViewer = (text) => {
    return text?.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {/* <img src="/images/N1_3_CEL.gif" alt="some"/> */}
        {line?.split(spliterHandler(line)).map((textWithMedia, indexImage) => {
          if (indexImage > 0) {
            // console.log(textWithMedia);
            const pathOfImage = bookModifierService.getImagePathByName(
              String(textWithMedia)?.substring([0], textWithMedia?.indexOf("}"))
            );
            const videoName = String(textWithMedia)?.substring(
              [0],
              textWithMedia?.indexOf("}")
            );
            const findedVideo = {
              ...videos.find((v) => v.name == videoName),
              height: isMobile ? "100%" : "80%",
              width: isMobile ? "100%" : "80%",
            };
            //console.log(findedVideo);
            return (
              <React.Fragment key={indexImage}>
                {/* <img src={pathOfImage + "a"} alt="some-view" />; */}
                {swithButtonHandler(line, pathOfImage, findedVideo)}
                <span>
                  {String(textWithMedia)
                    .substring(textWithMedia.indexOf("}"))
                    .replace("}", "")}
                </span>
              </React.Fragment>
            );
          }
          return textWithMedia;
        })}
        {index !== text.split("\n").length - 1 && <br />}
      </React.Fragment>
    ));
  };

  return (
    <>
      {/* <div ref={iframeRef} onLoad={handleIframeLoad}>
        <IFrame props={ifrmeValue} />
      </div> */}
      {/* <iframe
        ref={iframeRef}
        className="videoUrl"
        hidden  
        title="key" 
        src={pCloudUrl}
      /> */}
      {/* {setIframeSrc(document.querySelector("iframe"))} */}
      {/* {console.log(iframeRef?.current)}
      {console.log(iframeRef?.current?.contentWindow?.document)} */}
      {/* {console.log(document.getElementsByTagName("pre")[0]?.innerHTML)}
      {console.log(document.querySelector("iframe")?.contentDocument)} */}

      <Dialog
        visible={openTextImage}
        style={{
          width: isMobile ? "95%" : "auto",
          objectFit: "fill",
          height: "auto",
          zoom: "80%",
        }}
        header={card.h3}
        modal
        onHide={() => {
          setTextImage("");
          setTextVideo("");
          setOpenTextImage(false);
        }}
        // onHide={setOpenTextImage(false)}
      >
        {textImage && textImage != "" && (
          <img
            className="text-image"
            style={{ width: isMobile ? "84vw" : "50vw", border: "none" }}
            src={textImage}
            onError={(e) => (e.target.src = "/images/snow-sports.png")}
            alt="some-view"
          />
        )}
        {textVideo && textVideo != "" && (
          <VideoPlayer
            className="card flex"
            // src={textVideo.videoUrl}
            // options={{ autoplay: true, loop: true, ratio: 5 / 4 }}
            options={{ isMobile, windowInnerWidth, windowInnerHeight }}
            setHide={true}
            props={textVideo}
          />
        )}
      </Dialog>

      {card && (
        <div className="card-container">
          {
            loadVideo && video && (
              <VideoPlayer
                // src={video.videoUrl}
                // options={{ autoplay: true, loop: true, ratio: 5 / 4 }}
                options={{ isMobile, windowInnerWidth, windowInnerHeight }}
                setHide={true}
                props={video}
              />
            )
            // <VideoComponent props={video} />
            // <VideoCanvas data={video} />
          }
          {loadCard && (
            <div>
              {/* <ImageCanvas url={card?.img} alt="book-fig" /> */}
              <ImageGaleria
                imagesItem={imagesItem}
                onImageGaleriaLoad={onImageGaleriaLoad}
                onImageGaleriaFallBack={onImageGaleriaFallBack}
              />
              <h3>{card?.h3}</h3>
              <h5>{card?.h5}</h5>
              <p>{cardViewer(card?.p)}</p>
            </div>
          )}
          {loadDescription && (
            <div className="card-description">
              <h3>{card?.h3}</h3>
              {/* <p>{card?.description}</p> */}
              <div>{cardViewer(card?.description)}</div>
            </div>
          )}
          {loadMethodology && (
            <div className="card-methodology">
              <h3>{card?.h3}</h3>
              <div>{cardViewer(card?.methodology)}</div>
            </div>
          )}
          {loadLearnErrors && userSessionStorage.getLocalize() === "bg" && (
            <div className="card-learn-errors">
              <h3>{card?.h3}</h3>
              <div>{cardViewer(card?.learnErrors)}</div>
            </div>
          )}
          <br />
          <Button
            className="mr-6"
            icon={loadCard ? "pi pi-home" : "pi pi-directions-alt"}
            rounded
            outlined
            severity="info"
            aria-label="Home"
            onClickCapture={goHome}
          />
          {card?.videoName && (
            <Button
              disabled={loadVideo}
              className="mr-2"
              icon="pi pi-video"
              rounded
              outlined
              severity="danger"
              aria-label="Video"
              onClickCapture={() => openVideo(card?.videoName)}
            />
          )}
          {card?.methodology && (
            <Button
              className="mr-2"
              icon="pi pi-eye"
              rounded
              outlined
              severity="help"
              aria-label="Methodology"
              onClickCapture={(e) => loadMethodologyHandler(e)}
            />
          )}
          {card?.description && (
            <Button
              className="mr-2"
              icon="pi pi-ticket"
              rounded
              outlined
              severity="warning"
              aria-label="Description"
              onClickCapture={(e) => loadDescriptionHandler(e)}
            />
          )}
          {card?.learnErrors && (
            <Button
              className="mr-2"
              icon="pi pi-exclamation-triangle"
              rounded
              outlined
              severity="secondary"
              aria-label="Learn errors"
              onClickCapture={(e) => loadLearnErrorsHandler(e)}
            />
          )}
        </div>
      )}
      {/* {loadIframe && iframeRef?.current && (
        <HtmlToJsonConverter props={iframeRef?.current} />
      )} */}
    </>
  );
};

export default Card;
